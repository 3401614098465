.rewards-details-display,
.rewards-checkout,
.payment-settings,
.rewards-details {
  background-color: #f0f7f8;
  width: 100%;

  &__title {
    font-size: 36px;
    line-height: 51px;
    color: #383838;
    font-weight: bold;
    margin: 0;
  }

  &__details {
    color: #2c2c2c;
  }
}

.rewards-details:not(.rewards-details-display) {
  background-color: #ffffff;
}

.rewards-details {
  &__header {
    padding: 20px 20px 0 20px;
    background-color: #f0f7f8;
  }

  &__title {
    font-size: 36px;
    line-height: 51px;
    color: #383838;
    font-weight: bold;
    margin: 0;
  }

  &__details {
    color: #2c2c2c;
  }

  &__back-button {
    background-color: transparent;
    border: none;
    color: #2c2c2c;
    cursor: pointer;
    padding: 0;

    i {
      margin-right: 4px;
    }
  }

  &__children {
    flex: 1;
    padding: 0px 20px 0 20px;
  }

  .ant-btn-link {
    color: #0071bc;
    padding: 0;
    span,
    a {
      text-decoration: underline;
    }
  }

  &__actions-wrapper {
    margin-bottom: 12px;
    .ant-typography-danger {
      color: #bc4b51;
    }

    &__mark-rewarded-btn {
      background-color: #49d0d4;
      color: #000;
      padding: 7px 22px;
      border-radius: 5px;
      margin-top: 10px;

      &:hover {
        color: #000;
        background-color: #49d0d4;
        border-color: #000;
      }

      &:disabled {
        pointer-events: none;
      }
    }
  }

  &__content {
    @media (max-width: $container-lg) {
      margin-bottom: 70px;
    }

    &.disable {
      @media (max-width: $container-lg) {
        margin-bottom: 0px;
      }
    }

    &__right-panel {
      margin-top: 8px;

      @media (max-width: $container-lg) {
        position: fixed;
        background-color: #f0f7f8;
        padding: 20px;
        bottom: 0;
        box-shadow: 0px -3px 6px 0px rgba(0, 0, 0, 0.1);
      }

      &.disable {
        @media (max-width: $container-lg) {
          position: relative;
          background-color: transparent;
          box-shadow: unset;
        }
      }

      .ant-radio {
        &.ant-radio-checked {
          background-color: #1d4c4a;
        }
        .ant-radio-inner {
          background-color: #ffffff00;
        }
      }

      .ant-btn-danger {
        &:not(:disabled) {
          background-color: #bc4b51;
          border-color: #bc4b51;
        }
      }

      &__amount {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
      }

      &__cta {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        @media (max-width: $container-lg) {
          align-items: flex-start;
        }
      }
    }
  }

  @media (min-width: 768px) {
    &__sub-header {
      margin-bottom: 0;
    }
  }
}

.prospect-card {
  box-shadow: 0px 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  margin-bottom: rem(30px);
  font-size: 14px;
  & > .ant-card-body {
    padding: 0 rem(16px);
  }

  &__info {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-left: auto;
    margin-top: rem(8px);
    align-items: end;
    font-size: 16px;

    &__item {
      justify-content: space-between;
      width: 100%;
      gap: rem(24px);

      h4 {
        margin-bottom: 0;
      }
    }
  }

  &__prospect-info {
    padding: rem(12px) 0;

    h4 {
      margin-bottom: 0;
    }
  }

  &__recipient-list {
    margin-left: 0;
    padding: rem(4px) 0;

    .ant-table-wrapper {
      overflow: hidden;
    }

    .ant-table td {
      border-bottom: none;
      font-size: 16px;
    }

    .ant-table .ant-table-tbody > tr > td {
      padding-top: 8px;
      padding-bottom: 8px;
      .ant-btn {
        display: block;
        margin: 0 auto;
      }
      .ant-typography-secondary {
        font-size: 14px;
      }
      .ant-typography-danger {
        color: #bc4b51;
      }
    }
    .ant-table .ant-table-tbody > tr > td,
    .ant-table .ant-table-thead > tr > th {
      min-width: 0;
      background-color: #ffffff;
      text-align: left;
      .ant-checkbox-checked .ant-checkbox-inner {
        background: #1d4c4a;

        &::after {
          left: 22%;
        }
      }
    }

    .ant-table .ant-table-tbody > tr > td:nth-last-child(-n + 2),
    .ant-table .ant-table-thead > tr > th:nth-last-child(-n + 2) {
      text-align: center;
      padding-right: 0;
      padding-left: 0;
      text-transform: capitalize;
    }

    @media (min-width: 992px) {
      margin-left: 4%;
    }
  }

  &__more-options {
    position: absolute;
    right: 0;
    transform: rotate(90deg);

    & > button {
      padding: rem(16px) rem(8px);
    }

    & > button,
    &__menu > .ant-dropdown-menu > .ant-dropdown-menu-item > button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      color: #434343;

      &:hover {
        color: #434343;
        text-decoration: underline;
      }
    }

    &__menu
      > .ant-dropdown-menu
      > .ant-dropdown-menu-item:nth-child(1)
      > button {
      color: #bc4b51;

      &:hover {
        color: #bc4b51;
      }
    }
  }

  @media (min-width: 992px) {
    margin-bottom: rem(35px);
    &__prospect-info {
      padding: rem(18px) 0;
    }
    &__info {
      margin-left: 0;
      align-items: start;
      width: 100%;
    }
    & > .ant-card-body {
      padding: 0 rem(20px);
    }
  }
}

.recipient-reward {
  background-color: #ffffff;
  margin: 10px -20px 0 -20px;
  width: calc(100% + 40px);

  @media (min-width: $container-xlg) {
    margin: 40px -20px 0 -20px;
    display: flex;
  }

  &__list {
    padding: 1.25rem 1rem;
    ul {
      list-style: none;
      padding-inline-start: 0px;
      margin-block-start: 0px;
      margin-block-end: 0px;
      padding: 0;
    }

    @media (min-width: $container-xlg) {
      width: 40%;
      padding: 1.25rem 1.5rem;
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
    padding-top: 22px;
    padding: 1.25rem 1rem;
    border-top: 1px solid #d6d6d6;
    color: #555;
    // remove payment method content padding
    .payment-method__content {
      padding: 0;
    }

    @media (min-width: $container-xlg) {
      max-width: 480px;
      border-top: none;
      &.reward-checkout {
        border-radius: 6px;
        border: 1px solid var(--grey-200, #e8e9eb);
        background: var(--white, #fff);
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
      }
    }

    @media (min-width: $container-xxlg) {
      max-width: 600px;
    }

    &__total {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 2.5rem;
    }
    &__body {
      display: flex;
      gap: 0.5rem;
      margin: 1rem 0;
      font-size: 0.875rem;
      color: #72767E;
    }
    &__cta {
      display: flex;
      justify-content: flex-end;
      .ant-btn-primary {
        color: #000;
      }
    }
    .rewards-rg-pay-btn {
      display: block;
      width: 100%;
    }
    .stripe-info {
      font-size: 0.8125rem;
      color: #626262;
      margin: 0 auto;
      margin-top: 0.625rem;
    }
  }
}

.recipient-reward-detail {
  display: flex;
  flex-direction: column;

  &__item {
    display: flex;
    padding: 0.5rem 0;
    span,
    strong {
      color: #2e2e2e;
      font-size: 1.125rem;
    }
    span {
      margin-left: auto;
    }
    img {
      margin-right: 0.25rem;
    }
  }

  &__head {
    background-color: #f5f5f5;
    padding: 0.625rem 0.75rem;
    border-radius: 8px;
    min-height: 2.5rem;
  }
}

.recipient-reward-summary {
  display: flex;
  flex-direction: column;
  padding: 1.25rem 0;
  margin-top: 1.5rem;
  border-top: 1px solid #d6d6d6;
  &__title {
    margin-bottom: 0.75rem;
  }
  &__item {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
    margin-bottom: 0;
    span,
    strong {
      color: #2e2e2e;
      font-size: 1.125rem;
      display: flex;
      align-items: center;
      i {
        margin-left: 0.25rem;
      }
    }
  }
}

.prospect-card {
  box-shadow: 0px 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  margin-bottom: rem(30px);
  font-size: 14px;
  & > .ant-card-body {
    padding: 0 rem(16px);
  }

  &__info {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-left: auto;
    margin-top: rem(8px);
    align-items: end;
    font-size: 16px;

    &__item {
      justify-content: space-between;
      width: 100%;
      gap: rem(24px);

      h4 {
        margin-bottom: 0;
      }
    }
  }

  &__prospect-info {
    padding: rem(12px) 0;

    h4 {
      margin-bottom: 0;
    }
  }

  &__recipient-list {
    margin-left: 0;
    padding: rem(4px) 0;

    .ant-table-wrapper {
      overflow: hidden;
    }

    .ant-table td {
      border-bottom: none;
      font-size: 16px;
    }

    .ant-table .ant-table-tbody > tr > td {
      padding-top: 8px;
      padding-bottom: 8px;
      .ant-btn {
        display: block;
        margin: 0 auto;
      }
      .ant-typography-secondary {
        font-size: 14px;
      }
      .ant-typography-danger {
        color: #bc4b51;
      }
    }
    .ant-table .ant-table-tbody > tr > td,
    .ant-table .ant-table-thead > tr > th {
      min-width: 0;
      background-color: #ffffff;
      text-align: left;
      .ant-checkbox-checked .ant-checkbox-inner {
        background: #1d4c4a;

        &::after {
          left: 22%;
        }
      }
    }

    .ant-table .ant-table-tbody > tr > td:nth-last-child(-n + 2),
    .ant-table .ant-table-thead > tr > th:nth-last-child(-n + 2) {
      text-align: center;
      padding-right: 0;
      padding-left: 0;
      text-transform: capitalize;
    }

    @media (min-width: 992px) {
      margin-left: 4%;
    }
  }

  &__more-options {
    position: absolute;
    right: 0;
    transform: rotate(90deg);

    & > button {
      padding: rem(16px) rem(8px);
    }

    & > button,
    &__menu > .ant-dropdown-menu > .ant-dropdown-menu-item > button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      color: #434343;

      &:hover {
        color: #434343;
        text-decoration: underline;
      }
    }

    &__menu
      > .ant-dropdown-menu
      > .ant-dropdown-menu-item:nth-child(1)
      > button {
      color: #bc4b51;

      &:hover {
        color: #bc4b51;
      }
    }
  }

  @media (min-width: 992px) {
    margin-bottom: rem(35px);
    &__prospect-info {
      padding: rem(18px) 0;
    }
    &__info {
      margin-left: 0;
      align-items: start;
      width: 100%;
    }
    & > .ant-card-body {
      padding: 0 rem(20px);
    }
  }
}

.mark-rewarded-success,
.reward-checkout-success {
  &__content {
    padding: 1.5rem;
    h3.ant-typography {
      font-size: 2rem;
      line-height: 1.4rem;
      font-weight: bold;
    }
  }
  &__message {
    color: #383838;
  }
  &__title {
    width: 100%;
    border-radius: 10px;
    margin-bottom: 45px;
    .ant-card-body {
      padding: 1rem;
      display: flex;
      align-items: center;
    }
    svg {
      font-size: 2rem;
      color: #32936f;
    }
    h4 {
      font-size: 1.5rem;
      line-height: 1.4;
      color: #32936f;
      margin: 0;
      margin-left: 1rem;
    }
  }
  @media (min-width: 768px) {
    &__content {
      padding-top: 33px 76px 0 76px;
    }
  }
}
.mark-rewarded-success {
  @media (min-width: 576px) {
    &__title {
      width: 384px;
    }
  }
}
.reward-checkout-success {
  &__transaction-info {
    color: #383838;
    margin-top: 3rem;
    margin-bottom: 4rem;
  }
  @media (min-width: 576px) {
    &__title {
      width: 400px;
    }
    &__message {
      span:nth-child(2) {
        display: block;
      }
    }
  }
}
